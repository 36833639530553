import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import moment from "moment";

import {
  GET_SHOPS,
  GET_SHOP,
  DELETE_SHOP,
  UPDATE_SHOP,
  CREATE_SHOP,
  GET_SHOP_PRODUCTS,
  GET_SHOP_PRODUCT,
  UPDATE_SHOP_PRODUCT,
  CREATE_SHOP_PRODUCT,
  DELETE_SHOP_PRODUCT,
  GET_SHOP_SIZECHART,
  UPDATE_SHOP_SIZECHART,
  CREATE_SHOP_SIZECHART,
  DELETE_SHOP_SIZECHART,
  GET_SHOP_BRAND,
  UPDATE_SHOP_BRAND,
  CREATE_SHOP_BRAND,
  DELETE_SHOP_BRAND,
  GET_SHOP_BRANDS,
  GET_SHOP_SIZECHARTS,
  GET_BRANDS,
  GET_ANALYTICS,
  GET_LOOK_UUID,
  GET_AVATARS,
  TRY_ON,
} from "constants/ActionTypes";
import {
  getShopsSuccess,
  getShopsFailure,
  getShopFailure,
  getShopSuccess,
  deleteShopFailure,
  deleteShopSuccess,
  getShops,
  updateShopFailure,
  updateShopSuccess,
  createShopFailure,
  createShopSuccess,
  getShopProductsFailure,
  getShopProductsSuccess,
  getProductFailure,
  setShopSettings,
  getProductSuccess,
  updateProductFailure,
  updateProductSuccess,
  createProductFailure,
  createProductSuccess,
  deleteProductFailure,
  deleteProductSuccess,
  getShopBrandsFailure,
  getShopBrandsSuccess,
  getBrandsFailure,
  getBrandsSuccess,
  getShopSizechartsFailure,
  getShopSizechartsSuccess,
  deleteSizechartFailure,
  deleteSizechartSuccess,
  getSizechartFailure,
  getSizechartSuccess,
  getShopSizecharts,
  updateSizechartFailure,
  updateSizechartSuccess,
  createSizechartFailure,
  createSizechartSuccess,
  deleteBrandFailure,
  deleteBrandSuccess,
  getBrandFailure,
  getBrandSuccess,
  updateBrandFailure,
  updateBrandSuccess,
  createBrandFailure,
  createBrandSuccess,
  getShopBrands,
  getShopProducts,
  getAnalyticsFailure,
  getAnalyticsSuccess,
} from "../actions/Shops";

import {
  getLookuuidFailure,
  getLookuuidSuccess,
  getAvatarsFailure,
  getAvatarsSuccess,
  getTryonFailure,
  getTryonSuccess,
} from "../actions/Looks";
import TexelService from "../services/texel-service";
import { GET_SHOP_SETTINGS } from "../constants/ActionTypes";

function* fetchShops({ params }) {
  try {
    const api = new TexelService();
    const result = yield api.getShops(params);
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getShopsFailure(result.error));
    } else {
      yield put(getShopsSuccess(result.data, result.next_cursor, result.total));
    }
  } catch (error) {
    yield put(getShopsFailure(error));
  }
}

export function* shops() {
  yield takeEvery(GET_SHOPS, fetchShops);
}

function* fetchShop({ id }) {
  try {
    const api = new TexelService();
    const result = yield api.getShop(id);
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getShopFailure(result.error));
    } else {
      yield put(getShopSuccess(result.data));
    }
  } catch (error) {
    yield put(getShopFailure(error));
  }
}

export function* shop() {
  yield takeEvery(GET_SHOP, fetchShop);
}

function* removeShop({ id }) {
  try {
    const api = new TexelService();
    const result = yield api.deleteShop(id);
    if ((result.error && result.error !== "") || result.status !== "success") {
      yield put(deleteShopFailure(result.error));
    } else {
      yield put(deleteShopSuccess(result.data));
      yield put(getShops());
    }
  } catch (error) {
    yield put(deleteShopFailure(error));
  }
}

export function* deleteShop() {
  yield takeEvery(DELETE_SHOP, removeShop);
}

function* saveShop({ id, data, callback }) {
  console.log("saveShop", id, typeof data);
  try {
    const api = new TexelService();
    const result = yield api.updateShop(id, JSON.stringify(data));
    if ((result.error && result.error !== "") || !result.data) {
      yield put(updateShopFailure(result.error));
    } else {
      yield put(updateShopSuccess(result.data));
      if (callback) callback();
    }
  } catch (error) {
    yield put(updateShopFailure(error));
  }
}

export function* updateShop() {
  yield takeEvery(UPDATE_SHOP, saveShop);
}

function* addShop({ data, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.createShop(JSON.stringify(data));
    if ((result.error && result.error !== "") || !result.data) {
      yield put(createShopFailure(result.error));
    } else {
      yield put(createShopSuccess(result.data));
      yield put(getShops());
      if (callback) callback();
    }
  } catch (error) {
    yield put(createShopFailure(error));
  }
}

export function* createShop() {
  yield takeEvery(CREATE_SHOP, addShop);
}

function* fetchProducts({ id, params }) {
  try {
    const api = new TexelService();
    const result = yield api.getShopProducts(id, params);
    console.log("result", result);
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getShopProductsFailure(result.error));
    } else {
      yield put(getShopProductsSuccess({
        items: result.data,
        total: result.total,
        next_cursor: result.next_cursor,
      }));
    }
  } catch (error) {
    yield put(getShopProductsFailure(error));
  }
}

export function* products() {
  yield takeEvery(GET_SHOP_PRODUCTS, fetchProducts);
}

function* fetchShopBrands({ shop_id, params }) {
  try {
    const api = new TexelService();
    const result = yield api.getShopBrands(shop_id, params);
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getShopBrandsFailure(result.error));
    } else {
      yield put(getShopBrandsSuccess(result.data));
    }
  } catch (error) {
    yield put(getShopBrandsFailure(error));
  }
}

export function* shopBrands() {
  yield takeEvery(GET_SHOP_BRANDS, fetchShopBrands);
}

function* fetchShopSizecharts({ shop_id, params, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.getShopSizecharts(shop_id, params);
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getShopSizechartsFailure(result.error));
    } else {
      if (callback) {
        console.log('fetchShopSizecharts success!')
        callback(result.data);
      }
      yield put(getShopSizechartsSuccess(result.data));
    }
  } catch (error) {
    yield put(getShopSizechartsFailure(error));
  }
}

export function* shopSizecharts() {
  yield takeEvery(GET_SHOP_SIZECHARTS, fetchShopSizecharts);
}

function* fetchBrands() {
  try {
    const api = new TexelService();
    const result = yield api.getBrands();
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getBrandsFailure(result.error));
    } else {
      yield put(getBrandsSuccess(result.data));
    }
  } catch (error) {
    yield put(getBrandsFailure(error));
  }
}

export function* brands() {
  yield takeEvery(GET_BRANDS, fetchBrands);
}

export function* settings() {
  yield takeEvery(GET_SHOP_SETTINGS, fetchShopSettings);
}

function* fetchAnalytics({ id, dateStart, dateEnd }) {
  try {
    const api = new TexelService();
    const result = yield api.getAnalytics(id, {
      ...(dateStart
        ? { dateFrom: moment(dateStart).format("YYYY-MM-DDTHH:mm:ss") }
        : {}),
      ...(dateEnd
        ? { dateTo: moment(dateEnd).format("YYYY-MM-DDTHH:mm:ss") }
        : {})
    });
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getAnalyticsFailure(result.error));
    } else {
      yield put(getAnalyticsSuccess(result.data));
    }
  } catch (error) {
    yield put(getAnalyticsFailure(error));
  }
}

export function* analytics() {
  yield takeEvery(GET_ANALYTICS, fetchAnalytics);
}

function* fetchProduct({ shop_id, product_id, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.getProduct(shop_id, product_id);
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getProductFailure(result.error));
    } else {
      yield put(getProductSuccess(shop_id, product_id, result.data));
      if (callback) callback(result.data);
    }
  } catch (error) {
    yield put(getProductFailure(error));
  }
}

function* fetchShopSettings({ shop_id }) {
  console.log("fetchShopSettings shop_id: ", shop_id);
  const api = new TexelService();
  const result = yield api.getShopSettings(shop_id);
  console.log("fetchShopSettings result: ", result);
  yield put(setShopSettings(result.data));
}

export function* product() {
  yield takeEvery(GET_SHOP_PRODUCT, fetchProduct);
}

function* removeProduct({ shop_id, product_id }) {
  try {
    const api = new TexelService();
    const result = yield api.deleteProduct(shop_id, product_id);
    if ((result.error && result.error !== "") || result.status !== "success") {
      yield put(deleteProductFailure(result.error));
    } else {
      yield put(deleteProductSuccess(result.data));
      yield put(getShopProducts(shop_id));
    }
  } catch (error) {
    yield put(deleteProductFailure(error));
  }
}

export function* deleteProduct() {
  yield takeEvery(DELETE_SHOP_PRODUCT, removeProduct);
}

function* saveProduct({ shop_id, product_id, data, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.updateProduct(
      shop_id,
      product_id,
      JSON.stringify(data)
    );
    if ((result.error && result.error !== "") || !result.data) {
      yield put(updateProductFailure(result.error));
    } else {
      yield put(updateProductSuccess(result.data));
      if (callback) callback();
    }
  } catch (error) {
    yield put(updateProductFailure(error));
  }
}

export function* updateProduct() {
  yield takeEvery(UPDATE_SHOP_PRODUCT, saveProduct);
}

function* addProduct({ shop_id, data, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.createProduct(shop_id, JSON.stringify(data));
    if ((result.error && result.error !== "") || !result.data) {
      yield put(createProductFailure(result.error));
    } else {
      yield put(createProductSuccess(result.data));
      yield put(getShopProducts(shop_id));
      if (callback) callback(result.data);
    }
  } catch (error) {
    yield put(createProductFailure(error));
  }
}

export function* createProduct() {
  yield takeEvery(CREATE_SHOP_PRODUCT, addProduct);
}

function* fetchSizechart({ shop_id, sizechart_id, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.getSizechart(shop_id, sizechart_id);
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getSizechartFailure(result.error));
    } else {
      yield put(getSizechartSuccess(shop_id, sizechart_id, result.data));
      if (callback) {
        callback(result.data);
      }
    }
  } catch (error) {
    yield put(getSizechartFailure(error));
  }
}

export function* sizechart() {
  yield takeEvery(GET_SHOP_SIZECHART, fetchSizechart);
}

function* removeSizechart({ shop_id, sizechart_id }) {
  try {
    const api = new TexelService();
    const result = yield api.deleteSizechart(shop_id, sizechart_id);
    if ((result.error && result.error !== "") || result.status !== "success") {
      yield put(deleteSizechartFailure(result.error));
    } else {
      yield put(deleteSizechartSuccess(result.data));
      yield put(getShopSizecharts(shop_id));
    }
  } catch (error) {
    yield put(deleteSizechartFailure(error));
  }
}

export function* deleteSizechart() {
  yield takeEvery(DELETE_SHOP_SIZECHART, removeSizechart);
}

function* saveSizechart({ shop_id, sizechart_id, data, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.updateSizechart(
      shop_id,
      sizechart_id,
      JSON.stringify(data)
    );
    if ((result.error && result.error !== "") || !result.data) {
      yield put(updateSizechartFailure(result.error));
    } else {
      yield put(updateSizechartSuccess(result.data));
      if (callback) callback();
    }
  } catch (error) {
    yield put(updateSizechartFailure(error));
  }
}

export function* updateSizechart() {
  yield takeEvery(UPDATE_SHOP_SIZECHART, saveSizechart);
}

function* addSizechart({ shop_id, data, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.createSizechart(shop_id, JSON.stringify(data));
    if ((result.error && result.error !== "") || !result.data) {
      yield put(createSizechartFailure(result.error));
    } else {
      yield put(createSizechartSuccess(result.data));
      yield put(getShopSizecharts(shop_id));
      if (callback) callback();
    }
  } catch (error) {
    yield put(createSizechartFailure(error));
  }
}

export function* createSizechart() {
  yield takeEvery(CREATE_SHOP_SIZECHART, addSizechart);
}

function* fetchBrand({ shop_id, brand_id }) {
  try {
    const api = new TexelService();
    const result = yield api.getBrand(shop_id, brand_id);
    if ((result.error && result.error !== "") || !result.data) {
      yield put(getBrandFailure(result.error));
    } else {
      yield put(getBrandSuccess(shop_id, brand_id, result.data));
    }
  } catch (error) {
    yield put(getBrandFailure(error));
  }
}

export function* brand() {
  yield takeEvery(GET_SHOP_BRAND, fetchBrand);
}

function* removeBrand({ shop_id, brand_id }) {
  try {
    const api = new TexelService();
    const result = yield api.deleteBrand(shop_id, brand_id);
    if ((result.error && result.error !== "") || result.status !== "success") {
      yield put(deleteBrandFailure(result.error));
    } else {
      yield put(deleteBrandSuccess(result.data));
      yield put(getShopBrands(shop_id));
    }
  } catch (error) {
    yield put(deleteBrandFailure(error));
  }
}

export function* deleteBrand() {
  yield takeEvery(DELETE_SHOP_BRAND, removeBrand);
}

function* saveBrand({ shop_id, brand_id, data, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.updateBrand(
      shop_id,
      brand_id,
      JSON.stringify(data)
    );
    if ((result.error && result.error !== "") || !result.data) {
      yield put(updateBrandFailure(result.error));
    } else {
      yield put(updateBrandSuccess(result.data));
      if (callback) callback();
    }
  } catch (error) {
    yield put(updateBrandFailure(error));
  }
}

export function* updateBrand() {
  yield takeEvery(UPDATE_SHOP_BRAND, saveBrand);
}

function* addBrand({ shop_id, data, callback }) {
  try {
    const api = new TexelService();
    const result = yield api.createBrand(shop_id, JSON.stringify(data));
    if ((result.error && result.error !== "") || !result.data) {
      yield put(createBrandFailure(result.error));
    } else {
      yield put(createBrandSuccess(result.data));
      yield put(getShopBrands(shop_id));
      if (callback) callback();
    }
  } catch (error) {
    yield put(createBrandFailure(error));
  }
}

export function* createBrand() {
  yield takeEvery(CREATE_SHOP_BRAND, addBrand);
}

export default function* rootSaga() {
  yield all([
    fork(shops),
    fork(shop),
    fork(deleteShop),
    fork(updateShop),
    fork(createShop),
    fork(products),
    fork(product),
    fork(settings),
    fork(createProduct),
    fork(deleteProduct),
    fork(updateProduct),
    fork(sizechart),
    fork(createSizechart),
    fork(deleteSizechart),
    fork(updateSizechart),
    fork(brand),
    fork(createBrand),
    fork(deleteBrand),
    fork(updateBrand),
    fork(shopBrands),
    fork(shopSizecharts),
    fork(brands),
    fork(analytics),
  ]);
}
