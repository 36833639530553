import { firebase } from "../firebase/firebase";

import config from "config/config";
export default class TexelService {
  _apiBase = process.env.REACT_APP_API_URL;
  _apiProfile = process.env.REACT_APP_API_PROFILE_URL;
  _apiRecommend = process.env.REACT_APP_API_RECOMMEND_URL;
  _apiShop = process.env.REACT_APP_API_SHOP_URL || process.env.REACT_APP_API_RECOMMEND_URL;
  _apiAnalytics = process.env.REACT_APP_API_ANALYTICS_URL;
  _apiMonitoring = process.env.REACT_APP_API_MONITORING_URL;
  _apiTelegram = process.env.REACT_APP_API_TELEGRAM_URL;
  _apiHyperspaceBase = process.env.REACT_APP_API_HYPERSPACE_DEV_URL || process.env.REACT_APP_API_HYPERSPACE_URL;
  _apiWidgetBase = process.env.REACT_APP_API_WIDGET_URL || process.env.REACT_APP_API_HYPERSPACE_DEV_URL;
  _clientID = process.env.REACT_APP_CLIENT_ID;
  _clientProfileID = process.env.REACT_APP_CLIENT_ID_PROFILE;
  _clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  _clientSecretRecommend = process.env.REACT_APP_CLIENT_SECRET_RECOMMEND;
  _clientSecretProfile = process.env.REACT_APP_CLIENT_SECRET_PROFILE;
  _grantType = ["profile", "hyperspace", "recommend", "monitoring"];

  /*
  createUserProfile = async (profile) => {
    return this.getMethod('POST', 'createUserProfile', JSON.stringify(profile), true, true);
  }*/

  registrationsList = async () => {
    //TODO. remove x-token from here?
    return this.getMethod("GET", "feedback", null, true, true, {
      "x-token": "NegnpQhkX2ekIURNWo54"
    });
  };

  profileList = async () => {
    return this.getMethod(
      "GET",
      "user/list",
      null,
      true,
      true,
      {
        token: localStorage.getItem("recommend_auth_token")
      },
      this._apiProfile
    );
  };

  profile = async (id, params) => {
    return this.getMethod(
      "GET",
      "user",
      null,
      true,
      true,
      { "X-User-Id": id },
      this._apiProfile,
      params
    );
  };

  saveSettings = async (shop_id, settings) => {
    return await fetch(`${this._apiRecommend}/shops/${shop_id}/settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("recommend_auth_token")
      },
      body: JSON.stringify(settings)
    });
  };

  removePose = async (id, avatar_uuid) => {
    return this.getMethod(
      "DELETE",
      `photos/model-pose/${avatar_uuid}`,
      null,
      true,
      true,
      { "X-User-Id": id },
      this._apiHyperspaceBase
    );
  };


  updateProfile = async (id, parameters) => {
    return this.getMethod(
      "PUT",
      "user",
      parameters,
      true,
      true,
      { "X-User-Id": id },
      this._apiProfile
    );
  };

  getMeasurements = async id => {
    return this.getMethod(
      "GET",
      "measurements",
      undefined,
      true,
      true,
      { "X-User-Id": id },
      this._apiProfile
    );
  };

  getCluster = async id => {
    return this.getMethod(
      "GET",
      `cluster/${id}`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  getShops = async params => {
    return this.getMethod(
      "GET",
      `shops`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend,
      params
    );
  };

  getStats = async params => {
    return this.getMethod(
      "GET",
      `stats`,
      undefined,
      true,
      true,
      {},
      this._apiMonitoring,
      params
    );
  };

  getTelegramStats = async params => {
    return this.getMethod(
      "GET",
      `stats`,
      undefined,
      true,
      true,
      {},
      this._apiTelegram,
      params
    );
  };

  getMonitoringLogs = async params => {
    return this.getMethod(
      "GET",
      `logs`,
      undefined,
      true,
      true,
      {},
      this._apiMonitoring,
      params
    );
  };

  getShop = async id => {
    return this.getMethod(
      "GET",
      `shops/${id}`,
      undefined,
      true,
      true,
      {},
      this._apiShop
    );
  };

  deleteShop = async id => {
    return this.getMethod(
      "DELETE",
      `shops/${id}`,
      undefined,
      true,
      true,
      {},
      this._apiShop
    );
  };

  updateShop = async (id, data) => {
    return this.getMethod(
      "PUT",
      `shops/${id}`,
      data,
      true,
      true,
      {},
      this._apiShop
    );
  };

  createShop = async data => {
    return this.getMethod(
      "POST",
      `shops`,
      data,
      true,
      true,
      {},
      this._apiShop
    );
  };

  getShopProducts = async (id, params) => {
    return this.getMethod(
      "GET",
      `shops/${id}/products`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend,
      params
    );
  };

  createProduct = async (id, data) => {
    return this.getMethod(
      "POST",
      `shops/${id}/products`,
      data,
      true,
      true,
      {},
      this._apiRecommend,
      { merge: false }
    );
  };

  createLookByUrl = async (url, shop_id, preview_url, parsing_url) => {
    return this.getMethod(
        "POST",
        `look/by_url`,
        JSON.stringify({ url, parsing_url, preview_url }),
        true,
        true,
        {
          'x-shop-id': shop_id
        },
        this._apiWidgetBase,
    );
  };

  getShopSettings = async shop_id => {
    return this.getMethod(
      "GET",
      `/shops/${shop_id}/settings`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  getProduct = async (id, product_id) => {
    return this.getMethod(
      "GET",
      `shops/${id}/products/${product_id}`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  updateProduct = async (id, product_id, data) => {
    return this.getMethod(
      "PUT",
      `shops/${id}/products/${product_id}`,
      data,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  deleteProduct = async (id, product_id) => {
    return this.getMethod(
      "DELETE",
      `shops/${id}/products/${product_id}`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  createSizechart = async (id, data) => {
    return this.getMethod(
      "POST",
      `shops/${id}/sizecharts`,
      data,
      true,
      true,
      {},
      this._apiRecommend,
      { merge: false }
    );
  };

  getSizechart = async (id, product_id) => {
    return this.getMethod(
      "GET",
      `shops/${id}/sizecharts/${product_id}`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  updateSizechart = async (id, product_id, data) => {
    return this.getMethod(
      "PUT",
      `shops/${id}/sizecharts/${product_id}`,
      data,
      true,
      true,
      {},
      this._apiRecommend,
      { merge: false }
    );
  };

  deleteSizechart = async (id, product_id) => {
    return this.getMethod(
      "DELETE",
      `shops/${id}/sizecharts/${product_id}`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  createBrand = async (id, data) => {
    return this.getMethod(
      "POST",
      `shops/${id}/brands`,
      data,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  getBrand = async (id, product_id) => {
    return this.getMethod(
      "GET",
      `shops/${id}/brands/${product_id}`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  updateBrand = async (id, product_id, data) => {
    return this.getMethod(
      "PUT",
      `shops/${id}/brands/${product_id}`,
      data,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  deleteBrand = async (id, product_id) => {
    return this.getMethod(
      "DELETE",
      `shops/${id}/brands/${product_id}`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  getShopBrands = async (id, params) => {
    return this.getMethod(
      "GET",
      `shops/${id}/brands`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend,
      params
    );
  };

  getBrands = async id => {
    return this.getMethod(
      "GET",
      `sizecharts`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend
    );
  };

  getAnalytics = async (id, params) => {
    return this.getMethod(
      "GET",
      `stats`,
      undefined,
      true,
      true,
      { "X-Shop-Id": id },
      this._apiAnalytics,
      params
    );
  };

  getlookUuid = async (url) => {
    return this.getMethod(
      "POST",
      `look`,
      JSON.stringify({ images: [url] }),
      true,
      true,
      {},
      this._apiHyperspaceBase,
    );
  };

  uploadlookAndGetUuid = async (base64, shop_id) => {
    return this.getMethod(
      "POST",
      `look`,
      JSON.stringify({ base64 }),
      true,
      true,
      {
       'x-shop-id': shop_id
      },
      this._apiWidgetBase,
    );
  };

  getAvatars = async (sex, all) => {
    return this.getMethod(
      "GET",
      `avatars`,
      undefined,
      true,
      true,
      {},
      this._apiWidgetBase,
      all ? {sex: "all", defaultAvatars: "all"} : { sex },
    );
  };
  sendCacheResult = async (props) => {
    return this.getMethod(
      "POST",
      `tryon/result`,
      JSON.stringify(props),
      true,
      true,
      {},
      this._apiWidgetBase,
    );
  };

  tryon = async (look_uuid, avatar_uuid, tryon_algorithm = "t1", look_url) => {
    console.log("service getTryon", look_uuid, avatar_uuid, tryon_algorithm, look_url)
    return this.getMethod(
      "POST",
      `tryon/basic`,
      JSON.stringify({
        look_uuid,
        avatar_uuid,
        tryon_algorithm: "t1",
        look_url,
      }),
      true,
      true,
      {},
      this._apiWidgetBase,
    );
  };

  getShopSizecharts = async (id, params) => {
    return this.getMethod(
      "GET",
      `shops/${id}/sizecharts`,
      undefined,
      true,
      true,
      {},
      this._apiRecommend,
      params
    );
  };

  getSizes = async (id, shop_id) => {
    return this.getMethod(
      "GET",
      `shops/${shop_id}/sizes`,
      undefined,
      true,
      true,
      { "X-User-Id": id },
      this._apiRecommend
    );
  };

  getLogs = async id => {
    return this.getMethod(
      "GET",
      `statistics`,
      undefined,
      true,
      true,
      { "X-User-Id": id },
      this._apiProfile
    );
  };

  saveMeasurements = async (id, measures_id, parameters) => {
    return this.getMethod(
      "PUT",
      `measurements/${measures_id}`,
      parameters,
      true,
      true,
      { "X-User-Id": id },
      this._apiProfile
    );
  };

  uploadImage = async (formData, shop_id) => {
    console.log('uploadImage formData', formData, typeof formData)
    console.log('uploadImage this._apiProfile', this._apiProfile)

    const headers = {
      "x-shop-id": shop_id,
      "token": localStorage.getItem("recommend_auth_token")
    };
    const res = await fetch(`${this._apiProfile}files`, {
      method: "POST",
      body: formData,
      headers: headers
    });
    return await res.json();
  };

  usersAppGetUsersList = async () => {
    return this.getMethod("GET", "users/app/getUsersList", null, true, true);
  };

  usersAppDeleteUsers = async uids => {
    return this.getMethod(
      "POST",
      "users/app/deleteUsers",
      JSON.stringify(uids),
      true,
      true
    );
  };

  usersCrmSetUserRole = async (uid, role) => {
    return this.getMethod(
      "POST",
      "users/crm/setUserRole",
      JSON.stringify({ uid, role }),
      true,
      true
    );
  };

  usersCrmSaveUser = async data => {
    return this.getMethod(
      "POST",
      "users/crm/saveUser",
      JSON.stringify({ data }),
      true,
      true
    );
  };

  usersCrmDeleteUser = async uid => {
    return this.getMethod(
      "POST",
      "users/crm/deleteUser",
      JSON.stringify({ uid }),
      true,
      true
    );
  };

  taskStatusChanged = async task_id => {
    return this.getMethod(
      "GET",
      "taskStatusChanged?task_id=" + task_id,
      null,
      true,
      true
    );
  };

  taskFilesList = async task_id => {
    //TODO. remove x-token from here?
    return this.getMethod("GET", "taskFilesList/" + task_id, null, true, true, {
      "x-token": "ycad54cmCBhoSjnCBiks"
    });
  };

  sendSMS = async task_id => {
    return this.getMethod("GET", "sendTaskReadySMS?task_id=" + task_id);
  };

  sendRescanSMS = async task_id => {
    return this.getMethod("GET", "sendRescanSMS?task_id=" + task_id);
  };

  getMethod = async (
    type,
    method,
    body,
    texel_key = true,
    fb_token = false,
    headers = {},
    base,
    query
  ) => {
    if (base) {
      if (base === this._apiRecommend) {
        console.log("localS", localStorage.getItem("recommend_auth_token"));
        headers["Authorization"] = `Bearer ${localStorage.getItem(
          "recommend_auth_token"
        )}`;
      } else if (base === this._apiShop) {
        headers["Authorization"] = `Bearer ${localStorage.getItem(
          "recommend_auth_token"
        )}`;
      } else if (base === this._apiMonitoring) {
        headers[
          "Token"
        ] = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlIjoic2VsZW5pdW0ifQ.b_Yjr4rFZUi-WxKytUTaq8hV2OwXZUyw14nCKePzwDI`;
      } else if (base === this._apiTelegram) {
        headers[
          "Token"
        ] = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlIjoic2VsZW5pdW0ifQ.b_Yjr4rFZUi-WxKytUTaq8hV2OwXZUyw14nCKePzwDI`;
      } else if (base === this._apiAnalytics) {
        headers[
          "Token"
        ] = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlIjoidGVsZWdyYW0tYm90In0.IBW1qv2N6bYlmfP_axorG1ErrcQYefX6mQYJJmqgn74`;
      } else if (base === this._apiHyperspaceBase || base === this._apiWidgetBase) {
        headers[
          "token"
        ] = localStorage.getItem("recommend_auth_token");
      } else {
        headers["Authorization"] = `Bearer ${localStorage.getItem(
          "auth_token"
        )}`;
      }
    } else {
      base = this._apiBase;
    }
console.log('11', type, method)
    headers["Content-Type"] = "application/json";
    //if ( texel_key && localStorage.getItem("texel_key") !== null) {
      console.log('22')
    if (localStorage.getItem("texel_key")) headers["texel-key"] = localStorage.getItem("texel_key");
    //};
    const params = query
      ? "?" +
        Object.keys(query).reduce(
          (r, param, ind) =>
            `${r}${ind > 0 ? "&" : ""}${param}=${query[param]}`,
          ""
        )
      : "";
      console.log('33', fb_token)
   /* if (fb_token === true) {
      await firebase
        .auth()
        .currentUser.getIdToken(false)
        .then(idToken => {
          headers["fb-token"] = idToken;
        })
        .catch(function(error) {
          console.log(error);
        });
    }*/
      console.log('44', base, method, params, type, body, headers)
    const res = await fetch(`${base}${method}${params || ''}`, {
      method: type,
      body: body,
      headers: headers
    });
    console.log('resres', res)

    if (res.status === 401) {
      return { error: "Permission denied" };
    }

    /*if (!res.ok) {
      throw new Error(`Could not fetch ${base}${method}` +
        `, received ${res.status}`)
    }*/
    return await res.json();
  };

  getAuthToken = async () => {
    const res = await fetch(`${this._apiProfile}token`, {
      method: "POST",
      body: JSON.stringify({
        client_id: this._clientProfileID,
        client_secret: this._clientSecretProfile,
        grant_type: this._grantType
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });
    let json;
    try {
      json = await res.json();
      return json;
    } catch (e) {
      throw e;
    }
  };
  getAuthRecommendToken = async () => {
    const res = await fetch(`${this._apiRecommend}token`, {
      method: "POST",
      body: JSON.stringify({
        client_id: this._clientID,
        client_secret: this._clientSecretRecommend,
        grant_type: this._grantType
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });
    let json;
    try {
      json = await res.json();
      return json;
    } catch (e) {
      throw e;
    }
  };

  /*













  getProducts4Fit = async (texel_key) => {
    return this.getMethod('GET','products4fit?texel_key='+texel_key);
  }














  deleteUser = async (uid) => {
    return this.getMethod('POST', 'deleteUser', JSON.stringify({uid}), true, true);
  }

  getProfile = async () => {
    return this.getMethod('POST','profile', undefined, true, true);
  };


  getLook = async () => {
    return this.getMethod('POST','look');
  }

  deleteAccount = async () => {
    return this.getMethod('POST','deleteAccount', undefined, true, true);
  }


  getFitsYouBest = async (category) => {
    const profile = JSON.parse(localStorage.getItem("texel_profile" ));

    return this.getMethod('POST','fitsYouBest', JSON.stringify({
      category: category,
      measurements: profile.latestMeasurement.data
    }));
  }

  getFilterParams = async () => {
    return this.getMethod('GET','filterParams');
  }



  sendTryOnMeRequest = async (sku) => {
    return this.getMethod('POST','tryonmerequest',JSON.stringify({sku}));
  }


  getRecommendations = async () => {
    return this.getMethod('POST','recommendations');
  }

  getYouMayAlsoLike = async (sku) => {
      return this.getMethod('POST','youMayAlsoLike', JSON.stringify({sku}), true);
  }

  emailByLink = async (key) => {
    return this.getMethod('POST','emailByLink', JSON.stringify({key}), false);
  }
  */

  /*




  lookIncludes = async (look_id) => {
    if ( localStorage.getItem("txl_profile" ) === null ) {
      alert("PROBLEM: TEXEL PROFILE ISN'T DEFINED");
      return;
    }

    const profile = JSON.parse(localStorage.getItem("txl_profile" ));
    const key = profile.id;

    const res = await fetch(`${this._apiBase}lookincludes`,{
      method:'POST',
      body: JSON.stringify({
        look_id: look_id,
      }),
      headers: {'TexelKey':key, 'Content-Type': 'application/json'},
    });


    if (!res.ok) {
      throw new Error(`Could not fetch ${this._apiBase}recommendations` +
          `, received ${res.status}`)
    }
    return await res.json();

  }
*/
  /*


  getRecommendations = async () => {
    if ( localStorage.getItem("txl_profile" ) === null ) {
      alert("PROBLEM: TEXEL PROFILE ISN'T DEFINED");
      //TODO;
      return;
    }

    const profile = JSON.parse(localStorage.getItem("txl_profile" ));
    console.log(profile);
    const key = profile.id;

    const res = await fetch(`${this._apiBase}recommendations`,{
      method:'POST',
      headers: {'TexelKey':key},
    });


    if (!res.ok) {
      throw new Error(`Could not fetch ${this._apiBase}recommendations` +
          `, received ${res.status}`)
    }
    return await res.json();

  }

*/

  /*
    getFitsYouBest = async (category) => {
      if ( localStorage.getItem("txl_profile" ) === null ) {
        alert("PROBLEM: TEXEL PROFILE ISN'T DEFINED");
        //TODO;
        return;
      }

      const profile = JSON.parse(localStorage.getItem("txl_profile" ));
      const key = profile.id;

      const res = await fetch(`${this._apiBase}fitsyoubest`,{
        method:'POST',
        body: JSON.stringify({
          category: category,
          measurements: profile.latestMeasurement.data
        }),
        headers: {'TexelKey':key, 'Content-Type': 'application/json'},
      });


      if (!res.ok) {
        throw new Error(`Could not fetch ${this._apiBase}recommendations` +
            `, received ${res.status}`)
      }
      return await res.json();
    }
  */
}
